<template>
  <div class="card card-custom card-stretch">
    <div class="card-header border-0 pt-5" v-if="transportsToPay">
      <h3 class="card-title flex-column">
        <div class="d-flex align-items-center" style="height: 35px">
          <span class="card-label font-weight-bolder text-dark">
            รายการที่ต้องชำระเงิน
          </span>
          <span
            class="text-muted font-weight-bold font-size-sm"
            v-if="transportsToPay.recordsData"
          >
            Total {{ transportsToPay.recordsData.recordsFiltered }}+ transports
          </span>
        </div>
      </h3>
    </div>
    <div class="card-body pt-0 pb-6">
      <b-alert
        v-if="!transportsToPay || !transportsToPay.records.length"
        variant="warning"
        >ไม่มีรายการ</b-alert
      >
      <b-overlay :show="$apollo.loading" rounded="sm">
        <div class="d-flex flex-wrap">
          <div
            class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mr-6 mb-3"
          >
            <div class="d-flex align-items-center">
              <!--begin::Svg Icon | path: icons/duotone/Navigation/Arrow-down.svg-->
              <span class="svg-icon svg-icon-3 svg-icon-danger mr-2">
                <inline-svg src="/media/svg/icons/Communication/Sending.svg" />
              </span>
              <div
                class="fs-2 font-weight-bolder counted"
                data-kt-countup="true"
                data-kt-countup-value="75"
              >
                {{ this.selected.length }}
              </div>
            </div>
            <div class="fw-bold fs-6 text-gray-400">เลือกรายการ</div>
          </div>
          <div
            class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mr-6 mb-3"
          >
            <!--begin::Number-->
            <div class="d-flex align-items-center">
              <!--begin::Svg Icon | path: icons/duotone/Navigation/Arrow-up.svg-->
              <span class="svg-icon svg-icon-3 svg-icon-success mr-2">
                <inline-svg src="/media/svg/icons/Shopping/Bitcoin.svg" />
              </span>
              <!--end::Svg Icon-->
              <div
                class="fs-2 font-weight-bolder counted"
                data-kt-countup="true"
                data-kt-countup-value="60"
                data-kt-countup-prefix="%"
              >
                {{ format_number_pretty(total) }}
              </div>
            </div>
            <!--end::Number-->
            <!--begin::Label-->
            <div class="fw-bold fs-6 text-gray-400">ค่าขนส่ง</div>
            <!--end::Label-->
          </div>
        </div>
        <div class="table-responsive">
          <table
            v-if="transportsToPay && transportsToPay.records"
            class="table table-borderless table-vertical-center mb-8 table-sm"
            aria-describedby="รายการที่ต้องชำระเงิน"
          >
            <thead>
              <tr>
                <th
                  class="datatable-cell-center datatable-cell datatable-cell-check"
                >
                  <span style="width: 20px">
                    <label class="checkbox checkbox-single checkbox-all">
                      <input
                        type="checkbox"
                        :checked="checkedAllItem()"
                        v-on:change="onChangeAll($event)"
                      />&nbsp;
                      <span></span>
                    </label>
                  </span>
                </th>
                <th><span class="text-dark-75">เลขพัสดุ</span></th>
                <th class="text-center">
                  <span class="text-dark-75">ประเภทสินค้า</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">ถึงโกดังปลายทาง</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">จำนวน</span>
                </th>
                <th class="text-center text-nowrap">
                  <span class="text-dark-75">น้ำหนัก</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">ขนาด</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">CBM</span>
                </th>
                <th class="text-center text-nowrap">
                  <span class="text-dark-75">ตีลังไม้</span>
                </th>
                <th class="text-center text-nowrap">
                  <span class="text-dark-75">ค่าตีลังไม้</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">ขนส่ง</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">ล็อต</span>
                </th>
                <th class="text-center">
                  <span class="text-dark-75">ราคา</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in transportsToPay.records" v-bind:key="item._id">
                <td>
                  <span style="width: 20px">
                    <label class="checkbox checkbox-single">
                      <input
                        type="checkbox"
                        :value="item._id"
                        :checked="checkedItem(item._id)"
                        v-on:change="selectItem($event, item)"
                      />&nbsp;
                      <span> </span>
                    </label>
                  </span>
                </td>
                <td>
                  <router-link
                    target="_blank"
                    :to="`/forwarder/${item.transportId}`"
                  >
                    {{ item.transportNumber }}
                  </router-link>
                </td>
                <td class="text-center">
                  {{
                    item.userLevelPrice
                      ? item.userLevelPrice.userLevelDetailName
                      : "-"
                  }}
                </td>
                <td class="text-center">
                  {{
                    format_date(
                      item.timeline ? item.timeline.send_to_warehouse_th : ""
                    )
                  }}
                </td>
                <td class="text-center">
                  {{ item.amount }}
                </td>
                <td class="text-center">
                  {{ format_comma(item.size.weight * item.amount) || "-" }}
                </td>
                <td class="text-center text-nowrap">
                  {{
                    `${item.size.width || 0} x ${item.size.long || 0} x ${item
                      .size.height || 0}`
                  }}
                </td>
                <td class="text-center">
                  {{ calculateCube(item.size, item.amount) }}
                </td>
                <td class="text-center">
                  <span class="text-success" v-if="item.guarantee">เปิด</span>
                  <span class="text-danger" v-if="!item.guarantee">ปิด</span>
                </td>
                <td class="text-center">
                  {{ item.price_wooden_box }}
                </td>
                <td class="text-center">
                  <span
                    class="label label-lg label-inline"
                    v-bind:class="
                      `label-light-${
                        item.ship_by === 'ek' ? 'success' : 'primary'
                      }`
                    "
                  >
                    {{ item.ship_by === "ek" ? "รถ" : "เรือ" }}
                  </span>
                </td>
                <td class="text-center">
                  {{ item.lotId }}
                </td>
                <td class="text-center text-nowrap">
                  <div v-if="item.trackingPrice">
                    {{
                      item.trackingPrice.priceName === "kg"
                        ? "กิโล : "
                        : "ขนาด : "
                    }}
                    {{ item.trackingPrice.priceValue }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex mt-8" style="justify-content: flex-end">
          <button class="btn btn-primary" @click="save" style="width: 140px">
            ต่อไป
          </button>
        </div>
      </b-overlay>
    </div>
  </div>
</template>
<style lang="scss">
@import "./styles.scss";
</style>
<script>
import dayjs from "dayjs";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_PAYMENT_TRACKINGS } from "@/graphql/payshipping";
import bigDecimal from "js-big-decimal";
import { calculateCube, WEBSITE } from "@/config";
import { UPDATE_PAYSHIPPING } from "@/core/services/store/payshipping.module.js";

export default {
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `รายการพัสดุ`, route: "/forwarder" },
      { title: "ที่ต้องชำระ" }
    ]);
    document.title = `ที่ต้องชำระ | ${WEBSITE}`;
  },
  data() {
    return {
      logistics: null,
      address: null,
      valid: false,
      importPrice: 0,
      logisticsServicePricePrice: 0,
      deliveryPrice: 0,
      total: 0,
      selected: []
    };
  },
  methods: {
    calculateCube,
    checkedAllItem() {
      if (!this.selected.length) {
        return false;
      }

      return (
        this.transportsToPay.recordsData.recordsFiltered ===
        this.selected.length
      );
    },
    onChangeAll(event) {
      const checked = event.target.checked;
      if (!checked) {
        this.selected = [];
      } else {
        this.selected = [...this.transportsToPay.records];
      }
      this.priceLogistics();
    },
    checkedItem(item) {
      return this.selected.find(x => x._id === item);
    },
    selectItem(event, item) {
      const checked = event.target.checked;
      const found = this.selected.find(x => x._id === item._id);
      if (!found && checked) {
        this.selected.push(item);
      } else if (found) {
        const index = this.selected.indexOf(found);
        this.selected.splice(index, 1);
      }
      this.priceLogistics();
    },
    priceLogistics() {
      this.importPrice = this.selected.reduce(
        (total, x) =>
          total +
          (x.trackingPrice?.priceValue ?? 0) +
          (x.price_wooden_box ?? 0),
        0
      );

      this.total = this.importPrice;
    },
    format_number(val, decimal = 0) {
      return bigDecimal.round(val, decimal);
    },
    format_number_pretty(val = 0) {
      return bigDecimal.getPrettyValue(this.format_number(val, 2));
    },
    format_comma(val) {
      return Number(val).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },
    async save() {
      this.$store.dispatch(UPDATE_PAYSHIPPING, this.selected);
      this.$router.push("/forwarder/payshipping/checkout");
    },
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY");
      }
      return "-";
    }
  },
  apollo: {
    transportsToPay: {
      query: GET_PAYMENT_TRACKINGS
    }
  }
};
</script>
